.app-container {
    padding-bottom: 300px;
}

hr {
    width: 88%;
    margin-top: 40px;
    margin-bottom: 30px;
    border: 1px solid #484850;
}

@media only screen and (min-width: 1280px) {
    hr {
        width: 1200px;
    }
}
